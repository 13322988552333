import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    },
    color: 'alpha',
    marginBottom: '2%'
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`,
  },
  blockquote: {
    p: {
      color: `text`
    },
    fontStyle: `italic`,
    borderLeftStyle: `solid`,
    borderLeftColor: `alphaLight`,
    borderLeftWidth: `md`,
    mx: [0, 4],
    px: [3, 4],
    '&.translation': {
      fontSize: 2
    }
  }
}

const HeroContent = () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        Blockchain<span> Crypto</span><br></br> <span>Web3</span> & Beyond
      </Heading>
      <Heading variant='h2' sx={styles.running}>
        Sharing insights and experiences from the world of blockchain from a developers point of view.
      </Heading>
      <Heading variant='h3' sx={{ ...styles.running, ...styles.blockquote }} style={{ marginLeft: '0.1rem', paddingLeft: '1rem' }}>
        Fabian Jost
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      {/*}
      <Button as={Link} to='/'>
        Start Learning
      </Button>
      */}
      <Button variant='white' as={Link} to='/about' style={{ marginTop: '15px' }}>
        About Me
      </Button>
      {/* <Button variant='white' as={Link} to='https://lytevapes.com' style={{ marginTop: '15px' }}> */}
      {/*   My Business */}
      {/* </Button> */}
    </Box>
  </>
)

export default HeroContent
